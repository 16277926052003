<template>
    <custom-vue-table class="pl-3 pr-3" title="Transaction Reports" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                      :extra-params="{ start_date, status, end_date }">
        <template #filters>
            <div class="btn-group ml-3">
                <drop-btn text="Filters" class="ml-3" icon="fa fa-filter" size="md" dropdown-width="25r">
                    <div class="p-3">
                        <validated-vue-select name="Status" :options="statusOptions"
                                              label="Status"
                                              v-model="status"/>
                        <validated-input type="date" label="Start Date" name="Start Date"
                                         v-model="start_date"/>
                        <validated-input type="date" label="End Date" name="End Date"
                                         v-model="end_date"/>
                    </div>
                </drop-btn>
                <btn color="primary" size="sm" @click="TransactionReportDownload" text="Download"/>
            </div>
        </template>
    </custom-vue-table>
</template>

<script>
import urls from '@/data/urls';
import axios from 'secure-axios';

export default {
    name : 'TransactionReportDownload',
    data () {
        return {
            start_date    : '',
            end_date      : '',
            status        : '',
            listUrl       : urls.salesAdmin.transaction.list,
            statusOptions : [
                { value : 'Transaction Initiated', label : 'Transaction Initiated' },
                { value : 'Processing Stage', label : 'Processing Stage' },
                { value : 'Completed', label : 'Completed' },
                { value : 'Refund Initiated', label : 'Refund Initiated' },
                { value : 'Refund Processing', label : 'Refund Processing' },
                { value : 'Refund Completed', label : 'Refund Completed' }
            ],
            fields : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'order.id',
                    sortField : 'order_id',
                    title     : 'Order ID'
                },
                {
                    name      : 'transaction_mode',
                    sortField : 'transaction_mode',
                    title     : 'Transaction Mode'
                },
                {
                    name      : 'transaction_id',
                    sortField : 'transaction_id',
                    title     : 'Transaction ID'
                },
                {
                    name      : 'initiated_date',
                    sortField : 'initiated_date',
                    title     : 'Initiated Date'
                },
                {
                    name      : 'status',
                    sortField : 'status',
                    title     : 'Status'
                }

            ]
        };
    },
    methods : {
        async TransactionReportDownload () {
            this.loading = true;
            const component = this;
            const response = await axios.form(urls.salesAdmin.reports.transaction, {
                start_date : component.start_date,
                end_date   : component.end_date,
                status     : component.status
            });
            const json = response.data;
            if (json.error === false) {
                window.open(json.url);
                component.$notify('Downloaded Successfully', 'Downloaded', {
                    type : 'success'
                });
            } else {
                component.$notify('Failed to downloaded', 'Failed', {
                    type : 'danger'
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
